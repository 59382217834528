//@ts-check
import React, { useState, useEffect } from "react";
import { useAlert } from 'react-alert'

var device;
var server;
var service;
var characteristics;
var maxCounterInput = 5;
var passWordInput = "----";
var currentStoredPassword = "----";

    function BLEOperation(props) {
        const alert = useAlert()

        var LED_E = Object.freeze({
            LED_UNKNOWN:   "led_unknown",
            LED_GREEN:  "led_green",
            LED_RED: "led_red"
          });

        const [supportsBluetooth, setSupportsBluetooth] = useState(false);
        const [showMainPage, setShowMainPage] = useState(true);
        const [showCounterPage, setShowCounterPage] = useState(false);
        const [showManualPage, setShowManualPage] = useState(false);
        const [showSettingsPage, setShowSettingsPage] = useState(false);
        const [isDisconnected, setIsDisconnected] = useState(true);
        const [currentLedStatus, setLedStatus] = useState(LED_E.LED_GREEN);
        const [currentPassword, setPassword] = useState('----');
        const [currentMaxCounter, setMaxCounter] = useState(5);
        const [currentCounter, setCurrentCounter] = useState(0);
        const [isOccupantsMaxed, setOccupantsMaxed] = useState(false);
        const [isPasswordCheck, setPasswordCheck] = useState(false);

        const BLEcontrolServiceUID = '717f7cbe-b420-40e1-a675-cdd2b1f3e2af';   

      
        var AppCharacteristics = Object.freeze({
          UNKNOWN:   { name: Symbol("unknown"), UUID: '4b8f7367-d912-4881-93b1-a1ae02ed60be'},
          LED:  { name: Symbol("led"), UUID: '147a977e-e2d7-4011-bbfd-c262d1492642'},
          PASSWORD: { name: Symbol("password"), UUID: 'a10f826c-abf0-11ea-bb37-0242ac130002'},
          MAXCOUNTER:  { name: Symbol("maxcounter"), UUID: 'cf4d2d6f-4329-423a-b9ae-f8c2f9041b79'},
          CURRENTCOUNTER: { name: Symbol("currentcounter"), UUID: '3f30825c-fe0f-4d13-bfac-15c0b3299760'}
        });

        
        useEffect(() => {
            setShowMainPage(props.pageSelected[0].isVisible);
            setShowCounterPage(props.pageSelected[1].isVisible);
            setShowManualPage(props.pageSelected[2].isVisible);
            setShowSettingsPage(props.pageSelected[3].isVisible);

            if(!isDisconnected) currentCounter >= currentMaxCounter ? WriteLEDStatus(LED_E.LED_RED) : WriteLEDStatus(LED_E.LED_GREEN);
        }, [props.pageSelected]);
     
        // When the component mounts, check that the browser supports Bluetooth
        useEffect(() => {
            if (navigator.bluetooth) {
              setSupportsBluetooth(true);
            }
          }, []);

          useEffect(() => {
            //localStorage.removeItem("TraffikFlo_Password");

            //This gets the stored password and updates the first time the app runs
            currentStoredPassword = localStorage.getItem("TraffikFlo_Password");

              if(currentPassword === '999999' && currentStoredPassword == null){
                localStorage.setItem("TraffikFlo_Password", currentPassword);
              }
          }, [currentPassword]);
      
        /**
         * Let the user know when their device has been disconnected.
         */
        const onDisconnected = (event) => {
            alert.error(`The device ${event.target} is disconnected`);
            console.log('The device disconnected.')
            setIsDisconnected(true);
            
            reconnect();                

          }

        function reEstablishServices(server){
            // Get the service from the Bluetooth device
            service = server.getPrimaryService(BLEcontrolServiceUID);
              
            //Get the characteristics from the service
            getCharacteristics(service);
        }
        
          
        /**
         * Attempts to connect to a Bluetooth device and subscribe to
         * battery level readings using the battery service.
         */
        const connectToDeviceAndSubscribeToUpdates = async () => {

          try {
            // Search for Bluetooth devices that advertise a battery service
            device = await navigator.bluetooth
              .requestDevice({
                filters: [{namePrefix: 'Traffik'}],
                optionalServices: [BLEcontrolServiceUID]
              });
      
            console.log('> Name:             ' + device.name);
            console.log('> Id:               ' + device.id);
            console.log('> Connected:        ' + device.gatt.connected);
          
            // Add an event listener to detect when a device disconnects
            device.addEventListener('gattserverdisconnected', onDisconnected);
      
            // Try to connect to the remote GATT Server running on the Bluetooth device
            server = await device.gatt.connect();
            setIsDisconnected(false);
      
            // Get the service from the Bluetooth device
            service = await server.getPrimaryService(BLEcontrolServiceUID);
              
            //Get the characteristics from the service
            await getCharacteristics(service);

          } catch(error) {
              switch(error.code){
                    case 18: //Security error means device not paired
                        alert.info("Unless you've changed your PIN, use 999999 to pair your sign through your Bluetooth settings on your device.", {timeout: 0, 
                            onClose: () => {connectToDeviceAndSubscribeToUpdates()}}); 
                        break;
                    case 19: //Gatt device not paired means device not paired
                        alert.info("Unless you've changed your PIN, use 999999 to pair your sign through your Bluetooth settings on your device.", {timeout: 0, 
                            onClose: () => {connectToDeviceAndSubscribeToUpdates()}}); 
                        break;
                    default:
                        alert.error('Failed to connect to device');
                        break;
              }
            console.log(`There was an error: ${error}`);
          }
        };

        const handleLedClick = async () => {
            try {   
                var ledColour = currentLedStatus === LED_E.LED_GREEN ? LED_E.LED_RED : LED_E.LED_GREEN; 
                await WriteLEDStatus(ledColour);
            } catch(error) {
                console.log(`There was an error: ${error}`);
             }
        };

        const handleIncrement = async () => {
            try {
                var count = currentCounter + 1; 
                await WriteCurrentCounter(count);
            } catch(error) {
                console.log(`There was an error: ${error}`);
             }
        };

        const handleDecrement = async () => {
            try {   
                var count = currentCounter > 0 ? currentCounter - 1 : 0; 
                await WriteCurrentCounter(count);
            } catch(error) {
                console.log(`There was an error: ${error}`);
             }
        };

        const handleSetMaxOccupants = async () => {
            try {
                await WriteMaxCounter(maxCounterInput);
                alert.success("Max occupants updated!");
            } catch(error) {
                console.log(`There was an error: ${error}`);
            }
        };

        const handleMaxOccupantsChange = ({ target }) => {
            
            maxCounterInput = parseInt(target.value);
        };

        const handleClearTotalCounter = async () => {
            try {
                await WriteCurrentCounter(0);
                alert.success("Total counter has been cleared!");
            } catch(error) {
                console.log(`There was an error: ${error}`);
            }
        };

        const handleSetPassword = async () => {
            try {
                await WritePassword(passWordInput);
                alert.success("Password updated!");
                setTimeout(function () {window.location.reload()}, 2000);
            } catch(error) {
                console.log(`There was an error: ${error}`);
                alert.error("Password did not update!");
            }
        };

        const handleSetPasswordChange = ({ target }) => {
            
            passWordInput = target.value;
        };

        const handleCheckPassword = async () => {
            
            if (passWordInput === currentPassword){
                alert.success("Pin accepted!");
                localStorage.setItem("TraffikFlo_Password", passWordInput);
                setPasswordCheck(false);
                setShowManualPage(true);
                const updateSelectedPage = 
                [
                  { id: 1, name: "mainPage", isVisible: false},
                  { id: 2, name: "counterPage", isVisible: false },
                  { id: 3, name: "manualPage", isVisible: true },
                  { id: 4, name: "settingsPage", isVisible: false }
                ];
                props.getPageSelected(updateSelectedPage);
            }else{
                alert.error("Pin is incorrect!");
                setTimeout(function () {window.location.reload()}, 2000);
            }
        };

        const handleCheckPasswordChange = ({ target }) => {
            
            passWordInput = target.value;
        };

        async function getCharacteristics(service){
            // Get the characteristic from the Bluetooth device
            characteristics = await service.getCharacteristics();
      
            // Get the characteristic values from the Bluetooth device
            var enc = new TextDecoder("utf-8");
      
            var ledStatus = LED_E.LED_GREEN;
            var password = '----';
            var maxCounter = 5;
            var currentCounter = 0;

            for (var i = 0; i < characteristics.length; i++){
              var values = await characteristics[i].readValue();
              console.log('Characteristic '+ i + ' UUID: ' + characteristics[i].uuid + ', Value: ' + enc.decode(values));
              switch(characteristics[i].uuid){
                    case AppCharacteristics.UNKNOWN.UUID:
                        break;
                    case AppCharacteristics.LED.UUID:
                        ledStatus = enc.decode(values);
                        break;
                    case AppCharacteristics.PASSWORD.UUID:
                        password = enc.decode(values);
                        break;
                    case AppCharacteristics.CURRENTCOUNTER.UUID:
                        currentCounter = parseInt(enc.decode(values));
                        break;
                    case AppCharacteristics.MAXCOUNTER.UUID:
                        maxCounter = parseInt(enc.decode(values));
                        break;
                    default:
                        break;
              }
            }
            if(currentStoredPassword !== password && currentStoredPassword != null){
                 setPasswordCheck(true);
                 setShowMainPage(false);
            }else{
                 alert.success('Connected to ' + device.name);
                 setShowMainPage(false);
                 setShowManualPage(true);
                 const updateSelectedPage = 
                 [
                   { id: 1, name: "mainPage", isVisible: false},
                   { id: 2, name: "counterPage", isVisible: false },
                   { id: 3, name: "manualPage", isVisible: true },
                   { id: 4, name: "settingsPage", isVisible: false }
                 ];
                 props.getPageSelected(updateSelectedPage);
            };

            setLedStatus(ledStatus);
            setPassword(password);
            setCurrentCounter(currentCounter);
            setMaxCounter(maxCounter);
            currentCounter >= maxCounter ? setOccupantsMaxed(true) : setOccupantsMaxed(false);
            maxCounterInput = maxCounter;
            passWordInput = password;
        }

        /**
         * @param {React.SetStateAction<string>} value
        */
        async function WriteLEDStatus(value){
            await WritetoDevice(AppCharacteristics.LED, value);
            setLedStatus(value);
        };

        /**
         * @param {React.SetStateAction<number>} value
        */
        async function WriteMaxCounter(value){
            await WritetoDevice(AppCharacteristics.MAXCOUNTER, value);
            setMaxCounter(value);
            value >=  currentMaxCounter ? setOccupantsMaxed(true) : setOccupantsMaxed(false);
        };

        /**
         * @param {React.SetStateAction<number>} value
        */
        async function WriteCurrentCounter(value){
            await WritetoDevice(AppCharacteristics.CURRENTCOUNTER, value);
            setCurrentCounter(value);
            value >=  currentMaxCounter ? setOccupantsMaxed(true) : setOccupantsMaxed(false);
        };

        /**
         * @param {React.SetStateAction<string>} value
        */
        async function WritePassword(value){
            alert.info('Saving...', {timeout: 0});
            await WritetoDevice(AppCharacteristics.PASSWORD, value);
            setPassword(value);
            
        };

        async function WritetoDevice(appCharacteristicType, value){

            var c = await service.getCharacteristic(appCharacteristicType.UUID);

            let encoder = new TextEncoder();
            
            if(isDisconnected){
                alert.error(`The device is disconnected`);
            }else{
                try {
                    console.log('Setting ' + appCharacteristicType.name.toString() +' Description...');
                    await c.writeValue(encoder.encode(value));
                
                    console.log('> ' + appCharacteristicType.name.toString() + ' Description changed to: ' + value);
                  } catch(error) {
                    console.log('Argh! ' + error);
                  }
            }

        };

        async function reconnect() {
            exponentialBackoff(3 /* max retries */, 2 /* seconds delay */,
              async function toTry() {
                time('Connecting to Bluetooth Device... ');
                alert.info(`Trying to reconnect...`, {timeout: 5000});
                server = await device.gatt.connect();
                
                // Get the service from the Bluetooth device
                service = await server.getPrimaryService(BLEcontrolServiceUID);
              
                //Get the characteristics from the service
                await getCharacteristics(service);
                return;
              },
              function success() {
                console.log('> Bluetooth Device reconnected.');
                setIsDisconnected(false);
              },
              function fail() {
                time('Failed to reconnect.');
                alert.error(`Failed to reconnect bluetooth.`);
              });
          }
          
         
          /* Utils */
          
          // This function keeps calling "toTry" until promise resolves or has
          // retried "max" number of times. First retry has a delay of "delay" seconds.
          // "success" is called upon success.
        function exponentialBackoff(max, delay, toTry, success, fail) {
            toTry().then(result => success(result))
            .catch(_ => {
              if (max === 0) {
                return fail();
              }
              time('Retrying in ' + delay + 's... (' + max + ' tries left)');
              setTimeout(function() {
                exponentialBackoff(--max, delay * 2, toTry, success, fail);
              }, delay * 1000);
            });
          }
          
        function time(text) {
            console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
          }


      return (
        <div className="BLEOperation">
      {isPasswordCheck && !showMainPage &&
        <div><br/><br/>
        <h5>Please enter you new PIN to proceed</h5><br/>
        <form>
            <div className="row justify-content-center container">
                <label className="col-5 col-form-label-md" >Device Pin:</label>
                <input type="numeric" className="col-4 form-control" onChange={handleCheckPasswordChange} placeholder="----"/>
            </div><br/>
        </form>
        <button className="btn btn-dark btn-circle btn-md" onClick={handleCheckPassword}>Enter</button><br/>
        </div>
      }
      {supportsBluetooth && isDisconnected && showMainPage && !isPasswordCheck &&
        <div><br/><br/>
            <h5>Make sure your TraffikFlo sign is on before scanning.</h5><br/><br/>
            <button className="btn btn-dark btn-circle btn-xl2" onClick={connectToDeviceAndSubscribeToUpdates}>SCAN DEVICES</button>
        </div>
      }
      {!supportsBluetooth && 
        <p>This browser doesn't support the Web Bluetooth API</p>
      }
      {showCounterPage && !isDisconnected && !isPasswordCheck &&
        <div><br/><br/>
            <button className="btn btn-success btn-circle btn-xl" 
                style={{backgroundColor: isOccupantsMaxed ? '#d9534f' : '#5cb85c'}}>{currentCounter}</button><br/><br/><br/>
            <div className="row justify-content-center">
                <button className="btn btn-dark btn-circle btn-md" onClick={handleDecrement}><b>-</b></button>&nbsp;&nbsp;&nbsp;
                <button className="btn btn-dark btn-circle btn-md" onClick={handleIncrement}><b>+</b></button>
            </div><br/><br/>
            <h6>Maximum Occupants: {currentMaxCounter}</h6>
            <h6>Total Occupants: {currentCounter}</h6>
            <h6>Use the + and - buttons to track people as they</h6>
            <h6>enter and exit your store</h6>
            <h6>SEE SETTINGS TO ADJUST MAX OCCUPANCY</h6>
        </div>
        }
      {showManualPage && !isDisconnected && !isPasswordCheck &&
        <div><br/><br/>
            <button className="btn btn-circle btn-xl" 
                style={{backgroundColor: currentLedStatus ===  LED_E.LED_GREEN ? '#5cb85c' : 'transparent'}} 
                onClick={handleLedClick}>GO</button><br/><br/><br/>
            <button className="btn btn-circle btn-xl" 
                style={{backgroundColor: currentLedStatus ===  LED_E.LED_RED ? '#d9534f' : 'transparent'}} 
                onClick={handleLedClick}>NO</button><br/>
        </div>
      }
      {showSettingsPage && !isDisconnected && !isPasswordCheck &&
        <div><br/>
            <h6>You can update your device PIN and Maximum Occupancy below.  PIN cannot start with 0.</h6>
            <form>
                <div className="row justify-content-center container">
                    <label className="col-4 col-form-label-lg" >Device Pin:</label>
                    <input type="numeric" className="col-3 form-control" onChange={handleSetPasswordChange} placeholder={currentPassword.toString()}/>
                </div>
            </form>
            <button className="btn btn-dark btn-circle btn-lg" onClick={handleSetPassword}>Update Device Pin</button><br/>
            <form>
                <div className="row justify-content-center container">
                    <label className="col-5 col-form-label-lg" >Max Occupants:</label>
                    <input type="numeric" className="col-2 form-control" onChange={handleMaxOccupantsChange} placeholder={maxCounterInput.toString()}/>
                </div>
            </form>
            <button className="btn btn-dark btn-circle btn-lg" onClick={handleSetMaxOccupants}>Update Max Occupants</button><br/><br/>
            <button className="btn btn-dark btn-circle btn-lg" onClick={handleClearTotalCounter}>Clear Total Counter</button><br/>
        </div>
      }
      </div>

      );
    }

    // const Loading = () => (
    //     <div id="loading" className="loadingClass">
    //         <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><br/>
    //         Loading...
    //     </div>
    //   )
      

export default BLEOperation;