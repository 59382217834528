import React, { Component } from "react";
import BLEOperation from "./components/BLEOperation";
import { transitions, positions, Provider as AlertProvider, types } from 'react-alert';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import AlertTemplate from 'react-alert-template-basic';
import "./test.js";



const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 2000,
  offset: '30px',
  type: types.SUCCESS,
  // you can also just use 'scale'
  transition: transitions.SCALE
}

class App extends Component {
  state = {
    pageToShow: [
      { id: 1, name: "mainPage", isVisible: true},
      { id: 2, name: "counterPage", isVisible: false },
      { id: 3, name: "manualPage", isVisible: false },
      { id: 4, name: "settingsPage", isVisible: false }
    ]
  };

  setPageToShow(selectedPage){

    // function setStateFunction(state) {
    //   return {...state, pageToShow: selectedPage};
    // }
    this.setState({pageToShow: selectedPage});
  }

  getPageToShowFromBLEOperation = (pagetoShow) => {
    this.setPageToShow(pagetoShow);
  }

  // handleRestart = () => {
  //   window.location.reload();
  // };

  handleCounterPage = () => {
    if(this.state.pageToShow[0].isVisible === true) return;

    const updateSelectedPage = 
    [
      { id: 1, name: "mainPage", isVisible: false},
      { id: 2, name: "counterPage", isVisible: true },
      { id: 3, name: "manualPage", isVisible: false },
      { id: 4, name: "settingsPage", isVisible: false }
    ];
    this.setPageToShow(updateSelectedPage);
  }

  handleManualPage = () => {
    if(this.state.pageToShow[0].isVisible === true) return;

    const updateSelectedPage = 
    [
      { id: 1, name: "mainPage", isVisible: false},
      { id: 2, name: "counterPage", isVisible: false },
      { id: 3, name: "manualPage", isVisible: true },
      { id: 4, name: "settingsPage", isVisible: false }
    ];
    this.setPageToShow(updateSelectedPage);
  }

  handleSettingsPage = () => {
    if(this.state.pageToShow[0].isVisible === true) return;

    const updateSelectedPage = 
    [
      { id: 1, name: "mainPage", isVisible: false},
      { id: 2, name: "counterPage", isVisible: false },
      { id: 3, name: "manualPage", isVisible: false },
      { id: 4, name: "settingsPage", isVisible: true }
    ];
    this.setPageToShow(updateSelectedPage);
  }

  render() {
    return (
      <AlertProvider template={AlertTemplate} {...options}>
      <div>
      {/* <div id="products"></div> */}

      <nav className="navbar navbar-dark bg-dark navbar-expand-sm">
        <div><a className="navbar-brand" href="/">TraffikFlo</a></div>
        <div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a onClick={this.handleCounterPage} className="nav-link" href="#counter"> COUNTER </a>
            </li>
            <li className="nav-item">
              <a onClick={this.handleManualPage}  className="nav-link" href="#manual"> MANUAL </a>
            </li>
            <li className="nav-item">
              <a onClick={this.handleSettingsPage} className="nav-link" href="#settings"> SETTINGS </a>
            </li>
          </ul>
        </div>
      </nav>
        <main className="container">
          <BLEOperation pageSelected={this.state.pageToShow} getPageSelected={this.getPageToShowFromBLEOperation}/>
          {/* <Router>
            <Route path="/" exact component={BLEOperation}/>
          </Router> */}
        </main>
      </div>
      </AlertProvider>
    );
  }
}

export default App;
